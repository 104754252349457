@import '../style/variables.scss';

.app {
    &__banner {
        width: 100%;
        background-color: $dark;
        height: 100px;
        padding: 0 25px 0 45px;
        display: grid;
        grid-template-columns: 152px auto 133px;
        margin-bottom: 45px;
        &-text {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #FFFFFF;
            padding-left: 83px;
            padding-top: 18px;
        }
    }
}